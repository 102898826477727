import camelCase from 'lodash.camelcase'

const PERIOD_TO_DAY: Record<string, number> = {
  weeklyBySaturday: 6,
  weeklyBySunday: 0,
  weeklyByMonday: 1,
}

export const useSearchFavoritePeriod = (minPercent = 50) => {
  const DEFAULT_PERIOD = { percent: minPercent, period: '' }
  const { $lcServicesSearch, $i18n } = useNuxtApp()
  // Search Bar V1
  const dateFilters = useSearchDateFilters()
  const { apiParamsDestinationIds } = useSearchLocationFilters()
  // Search Bar V2
  const { data: searchInputData } = useSearchInput()
  const { isNewHeader } = useHeaderV2()

  const destinationIds = computed(() =>
    isNewHeader.value
      ? [searchInputData.value.location.id?.toString()].filter(isNonEmptyString)
      : apiParamsDestinationIds.value,
  )
  const startDate = computed(() => {
    if (isNewHeader.value) {
      const result = $dayjs(searchInputData.value.startDate)
      return result.isValid() ? result.format('YYYY-MM-DD') : null
    }
    return dateFilters.startDate.value
  })

  const { data } = useAsyncData(
    'destination-favorite-period',
    async () => {
      if (startDate.value && destinationIds.value.length) {
        await $lcServicesSearch.searchController.getDestinationFavoritePeriod({
          minPercent,
          destinationId: destinationIds.value,
          startDate: startDate.value,
        })
        const { period, percent } =
          $lcServicesSearch.searchPresenter.vm.destinationFavoritePeriod
        return {
          percent,
          period: camelCase(period),
        }
      }
      return DEFAULT_PERIOD
    },
    {
      default: () => DEFAULT_PERIOD,
      immediate: Boolean(startDate.value && destinationIds.value.length),
      server: false,
      watch: [destinationIds, startDate],
    },
  )

  const startDay = computed(() =>
    startDate.value
      ? $dayjs(startDate.value, { format: 'YYYY-MM-DD', locale: 'en' }).day()
      : -1,
  )
  const favoritePeriod = computed(() =>
    startDay.value >= 0 &&
    data.value.period &&
    PERIOD_TO_DAY[data.value.period] !== startDay.value
      ? data.value
      : DEFAULT_PERIOD,
  )
  const isFavoritePeriodWeekly = computed(() =>
    favoritePeriod.value.period.startsWith('weekly'),
  )
  const isCorrectHousePeriod = computed(
    () => PERIOD_TO_DAY[data.value.period] === startDay.value,
  )
  const startPrevious = computed(() =>
    $dayjs(startDate.value, 'YYYY-MM-DD').set(
      'day',
      PERIOD_TO_DAY[data.value.period] * -1,
    ),
  )
  const startNext = computed(() =>
    $dayjs(startPrevious.value, 'YYYY-MM-DD').add(1, 'week'),
  )
  const nearPeriods = computed(() => {
    if (!startDate.value) return

    const previous = formatCompactPeriod(
      [startPrevious.value, startPrevious.value.add(1, 'week')],
      $i18n,
    )
    const next = formatCompactPeriod(
      [startNext.value, startNext.value.add(1, 'week').format('YYYY-MM-DD')],
      $i18n,
    )
    return { previous, next }
  })

  const getClosestFavoritePeriod = (period: 'previous' | 'next') => {
    const baseDate = (period === 'next' ? startNext : startPrevious).value
    return $dayjs.isDayjs(baseDate)
      ? [baseDate.toDate(), baseDate.add(1, 'week').toDate()]
      : []
  }

  return {
    favoritePeriod,
    isCorrectHousePeriod,
    isFavoritePeriodWeekly,
    nearPeriods,
    getClosestFavoritePeriod,
  }
}
