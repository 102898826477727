<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const { locale, t } = useI18n<unknown, Locale>()
const { isMobile } = useBreakpoint()
const { trackEvent, pageType } = useTracking()

const emits = defineEmits<{ selected: [] }>()

const {
  allDestinations,
  breadcrumbsDestinationsIds,
  PLACEHOLDER_DESTI,
  setBreadcrumbsDestinationsIds,
  setData,
} = useSearchInput()

const currentDestination = computed(() => {
  const lastElement = [...breadcrumbsDestinationsIds.value].pop()

  const desti = allDestinations.value.find((d) => d.id === lastElement)
  return {
    ...desti,
    first_photo_url: desti?.first_photo_url || PLACEHOLDER_DESTI,
  }
})

const subDestinations = computed(() => {
  const lastElement = [...breadcrumbsDestinationsIds.value].pop()

  return [
    ...allDestinations.value
      .filter((d) => d.parent_id === lastElement)
      .map((d) => ({
        ...d,
        first_photo_url: d.first_photo_url || PLACEHOLDER_DESTI,
      })),
  ].sort((a, b) => b.houses_count - a.houses_count)
})

const breadcrumbs = computed(() =>
  [
    t('search.ourDestinations'),
    ...breadcrumbsDestinationsIds.value.map(
      (id) =>
        allDestinations.value.find((desti) => desti.id === id)?.name?.[
          locale.value
        ],
    ),
  ].filter(Boolean),
)

const handleClickBreadcrumb = (index: number) => {
  setBreadcrumbsDestinationsIds(
    breadcrumbsDestinationsIds.value.slice(0, index),
  )

  if (
    currentDestination.value.id &&
    currentDestination.value.clusterized_name
  ) {
    setData('location', {
      id: currentDestination.value.id.toString(),
      name: currentDestination.value.clusterized_name[locale.value],
      type: 'destination',
    })
  } else {
    setData('location', {})
  }
}

const handleClickDestination = (
  desti: {
    asChild: boolean
    clusterizedName: string
    id: number
    name: string
  },
  nextStep?: boolean,
) => {
  if (desti.asChild && !nextStep) {
    setBreadcrumbsDestinationsIds([
      ...breadcrumbsDestinationsIds.value,
      desti.id,
    ])
  } else {
    emits('selected')
  }

  setData('location', {
    id: desti.id.toString(),
    name: desti.clusterizedName,
    type: 'destination',
  })

  trackEvent({
    event: 'search_destination_click',
    destination_id: desti.id.toString(),
    destination_name: desti.name,
    page_type: pageType.value,
  })
}

const onKeyDown = (e: KeyboardEvent) => {
  if (e.code === 'Enter') emits('selected')
}

onMounted(() => {
  document.addEventListener('keydown', onKeyDown)
})

onUnmounted(() => {
  document.removeEventListener('keydown', onKeyDown)
})
</script>

<template>
  <div>
    <ul class="mb-4 flex items-center overflow-scroll text-left text-xs">
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb"
        :class="[
          'flex flex-none items-center ',
          breadcrumbs.length === index + 1 ? 'font-bold' : 'text-gray-400',
        ]"
      >
        <BaseIcon v-if="index" name="navArrowRight" :size="1" class="mx-1" />
        <button
          v-if="breadcrumbs.length !== index + 1"
          type="button"
          @click.stop="handleClickBreadcrumb(index)"
        >
          {{ breadcrumb }}
        </button>
        <template v-else>{{ breadcrumb }}</template>
      </li>
    </ul>

    <div class="md:max-h-[450px] md:overflow-scroll">
      <button
        v-if="currentDestination"
        type="button"
        class="group mb-6 flex w-full items-center justify-between gap-4 rounded-sm border border-gray-200 py-0.5 pl-0.5 pr-4 hover:bg-gray-100"
        @click="$emit('selected')"
      >
        <div class="flex-none">
          <NuxtImg
            :src="currentDestination.first_photo_url"
            class="rounded-l-sm"
            height="80"
            loading="lazy"
            width="134"
          />
        </div>
        <div class="grow text-left">
          <div class="text-md font-medium leading-5">
            {{
              $t('search.seeAllProperties', {
                destinationName: currentDestination.name?.[locale],
              })
            }}

            <span v-if="isMobile" class="lowercase">
              {{ $t('search.seeAll') }}</span
            >
            <template v-else>
              {{
                $t('search.propertyCount', {
                  count: currentDestination.houses_count,
                })
              }}
            </template>
          </div>
          <div v-if="isMobile" class="text-xs leading-4 text-gray-500">
            {{
              $t('search.propertyCount', {
                count: currentDestination.houses_count,
              })
            }}
          </div>
        </div>
        <div
          class="hidden flex-none items-center gap-1 rounded bg-secondary-100 px-2 py-1 text-xs font-semibold text-secondary-200 group-hover:bg-secondary-400 group-hover:text-white md:flex"
        >
          <span>{{ $t('search.seeAll') }}</span>
          <BaseIcon name="longArrowDownLeft" />
        </div>
      </button>

      <div
        class="grid grid-cols-2 items-start gap-x-2 gap-y-4 md:grid-cols-4 md:gap-y-8"
      >
        <button
          v-for="desti in subDestinations"
          :key="desti.name[locale]"
          class="text-left"
          type="button"
          @click.stop="
            handleClickDestination({
              asChild: Boolean(desti.child_ids.length),
              clusterizedName: desti.clusterized_name[locale],
              id: desti.id,
              name: desti.name[locale],
            })
          "
        >
          <div class="mb-2">
            <BaseNuxtImg
              :alt="desti.name[locale]"
              :src="desti.first_photo_url"
              class="rounded"
              height="83"
              loading="lazy"
              width="145"
            />
          </div>
          <div class="text-md font-medium leading-5">
            {{ desti.name[locale] }}
          </div>
          <div class="text-xs leading-4 text-gray-500">
            {{
              $t('search.propertyCount', {
                count: desti.houses_count,
              })
            }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
